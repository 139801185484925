














































































































































































































































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Resolve } from 'vue-di';
import DrugName from '~/components/DrugName.vue';
import LayoutModule from '~/store/layout';

@Component<ISIInfo>({
    components: {
        DrugName,
    },
})
export default class ISIInfo extends Vue {

    @Resolve
    public layout!: LayoutModule;

}
