// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@font-face{font-family:Campton Light;src:url(/assets/fonts/CamptonLight.otf) format(\"opentype\")}@font-face{font-family:Campton Medium;src:url(/assets/fonts/CamptonMedium.otf) format(\"opentype\")}@font-face{font-family:Campton SemiBold;src:url(/assets/fonts/CamptonSemiBold.otf) format(\"opentype\")}@font-face{font-family:Campton Book;src:url(/assets/fonts/CamptonBook.otf) format(\"opentype\")}@font-face{font-family:Campton Medium Italic;src:url(/assets/fonts/CamptonMediumItalic.otf) format(\"opentype\")}.isi-content h4{color:var(--color-secondary);font-weight:700}.isi-content .iri-section{padding:0 0 1em}.isi-content .iri-section:first-of-type .inner-iri-section{border-bottom:1px solid #ababab;padding-bottom:1em}.isi-content h3{font-size:1.25em;font-weight:400;color:var(--color-secondary)}.isi-content h4{font-size:1.15em}.isi-content .highlight{font-size:1.2em;font-weight:700}.isi-content a{color:var(--color-secondary);text-decoration:underline}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#e93cac",
	"secondaryColor": "#00bfb2",
	"accentColor": "#c41230",
	"magenta": "#e93cac",
	"orange": "#f47920",
	"lightOrange": "#faa61a",
	"yellow": "#ffc72c",
	"teal": "#00bfb2",
	"body-font-family1": "\"Campton Light\",sans-serif",
	"body-font-family2": "\"Campton Medium\",sans-serif",
	"body-font-family3": "\"Campton Medium Italic\",sans-serif",
	"campton-book": "\"Campton Book\",sans-serif",
	"campton-semi-bold": "\"Campton SemiBold\",sans-serif",
	"button": "#ee3d96"
};
module.exports = exports;
