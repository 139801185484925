// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@font-face{font-family:Campton Light;src:url(/assets/fonts/CamptonLight.otf) format(\"opentype\")}@font-face{font-family:Campton Medium;src:url(/assets/fonts/CamptonMedium.otf) format(\"opentype\")}@font-face{font-family:Campton SemiBold;src:url(/assets/fonts/CamptonSemiBold.otf) format(\"opentype\")}@font-face{font-family:Campton Book;src:url(/assets/fonts/CamptonBook.otf) format(\"opentype\")}@font-face{font-family:Campton Medium Italic;src:url(/assets/fonts/CamptonMediumItalic.otf) format(\"opentype\")}.color-picker{max-width:70%;position:relative}.color-picker .v-text-field__details{display:none}.color-picker .v-btn:not(.v-btn--round).v-size--default{height:48px}.color-picker .reset-button{position:absolute;right:-86px;top:24px}.color-swatch{cursor:pointer;margin-right:5px;height:40px;width:40px;border-radius:50%;transition:border-radius .2s ease-in-out}.v-color-picker button{display:none}.v-color-picker .v-input{max-width:79%}.v-color-picker .v-color-picker__input input{font-weight:700;color:rgba(0,0,0,.8);border:1px solid rgba(0,0,0,.5)}.v-color-picker .v-color-picker__input span{color:rgba(0,0,0,.8);font-weight:700}.v-color-picker__dot,.v-color-picker__preview .v-slider:not(.v-slider--disabled) .v-slider__thumb{box-shadow:0 3px 3px -3px #000,0 0 4px 0 #000,0 0 2px 0 #000}.theme--dark.v-text-field--solo>.v-input__control>.v-input__slot{background:#363636;padding-right:0!important}.v-text-field--outlined,.v-text-field--solo{border-radius:0}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#e93cac",
	"secondaryColor": "#00bfb2",
	"accentColor": "#c41230",
	"magenta": "#e93cac",
	"orange": "#f47920",
	"lightOrange": "#faa61a",
	"yellow": "#ffc72c",
	"teal": "#00bfb2",
	"body-font-family1": "\"Campton Light\",sans-serif",
	"body-font-family2": "\"Campton Medium\",sans-serif",
	"body-font-family3": "\"Campton Medium Italic\",sans-serif",
	"campton-book": "\"Campton Book\",sans-serif",
	"campton-semi-bold": "\"Campton SemiBold\",sans-serif",
	"button": "#ee3d96"
};
module.exports = exports;
