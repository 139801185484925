import colors from 'vuetify/es5/util/colors';
import { Theme, VuetifyThemeVariant } from 'vuetify/types/services/theme';

// Any color names added to theme will need to be added to this interface
interface IExtendedTheme extends VuetifyThemeVariant {
    brandGrey: string;
    mainBackground: string;
    headerBackground: string;
}
export default {
    dark: false,
    themes: {
        light: {
            primary: '#E93CAC',
            secondary: '#00BFB2',
            accent: colors.lightBlue.lighten2,
            error: '#DB3939',
            warning: colors.amber.lighten2,
            info: colors.lightBlue.lighten2,
            success: colors.green.lighten2,
            brandGrey: '#E6E6E6',
            mainBackground: '#fafafa',
            headerBackground: '#fafafa',
            magenta: '#E93CAC',
            orange: '#F47920',
            lightOrange: '#FAA61A',
            yellow: '#FFC72C',
            teal: '#00BFB2',
            button: '#EE3D96'
        } as Partial<IExtendedTheme>,
        dark: {
            primary: '#E93CAC',
            secondary: '#00BFB2',
            accent: colors.lightBlue.lighten2,
            error: '#DB3939',
            warning: colors.amber.lighten2,
            info: colors.lightBlue.lighten2,
            success: colors.green.lighten2,
            brandGrey: '#E6E6E6',
            mainBackground: '#fafafa',
            headerBackground: '#fafafa',
            magenta: '#E93CAC',
            orange: '#F47920',
            lightOrange: '#FAA61A',
            yellow: '#FFC72C',
            teal: '#00BFB2',
            button: '#EE3D96',
        } as Partial<IExtendedTheme>,
    },
} as Theme;
