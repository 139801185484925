export class Settings {
    public constructor(settings: any) {
        Object.assign(this, settings);
    }
}
export interface Settings {
    base: string;
    url(url: string): string;
    name: string;
    author: string;
    description: string;
    minDate: string;
    mainMenu: Array<{ icon: [string, string]; title: string; to: string; exact: boolean }>;
    errorPages: {
        default: { icon: [string, string]; title: string; message: string; statusCode: number };
        [code: string]: { icon: [string, string]; title: string; message: string; statusCode: number };
    };
    card: string;
    headerLogo: string;
    footerLogo: string;
    cardTypeQuestionId: string;
    cardTypeAnswerId: string;
    programId: string;
    surveyId: string;
    eligibilityFederalQuestion: string;
    eligibilityFederalAnswerYes: string;
    eligibilityFederalAnswerNo: string;
    eligibilityCitizenQuestion: string;
    eligibilityCitizenAnswerYes: string;
    eligibilityCitizenAnswerNo: string;
    patientConsentQuestion: string;
    patientConsentAnswerYes: string;
    patientConsentAnswerNo: string;
    triangleUp: string;
    triangleDown: string;
    lockEnabled: string;
    lockPassword: string;
    footerImg1: string;
    footerImg2: string;
    arrow: string;
    bridgeLogo: string;
    enrollmentLogo: string;
    centralLogo: string;
    pharmacyLogo: string;
    helpLogo: string;
    middleLine: string;
}
