import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a29daa1a = () => interopDefault(import('..\\pages\\bridge\\index.vue' /* webpackChunkName: "pages_bridge_index" */))
const _96d6062c = () => interopDefault(import('..\\pages\\unlock.vue' /* webpackChunkName: "pages_unlock" */))
const _7fe4e253 = () => interopDefault(import('..\\pages\\enrollment\\eligibility.vue' /* webpackChunkName: "pages_enrollment_eligibility" */))
const _7950b5ce = () => interopDefault(import('..\\pages\\enrollment\\error.vue' /* webpackChunkName: "pages_enrollment_error" */))
const _4fcb7f62 = () => interopDefault(import('..\\pages\\enrollment\\rejection.vue' /* webpackChunkName: "pages_enrollment_rejection" */))
const _bee9ca6e = () => interopDefault(import('..\\pages\\enrollment\\success.vue' /* webpackChunkName: "pages_enrollment_success" */))
const _6b846a1a = () => interopDefault(import('..\\pages\\errors\\400.vue' /* webpackChunkName: "pages_errors_400" */))
const _6b683b18 = () => interopDefault(import('..\\pages\\errors\\401.vue' /* webpackChunkName: "pages_errors_401" */))
const _6b2fdd14 = () => interopDefault(import('..\\pages\\errors\\403.vue' /* webpackChunkName: "pages_errors_403" */))
const _6b13ae12 = () => interopDefault(import('..\\pages\\errors\\404.vue' /* webpackChunkName: "pages_errors_404" */))
const _01b7f398 = () => interopDefault(import('..\\pages\\errors\\500.vue' /* webpackChunkName: "pages_errors_500" */))
const _29dc9b9c = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages_index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('#{BasePath}#/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/bridge",
    component: _a29daa1a,
    name: "bridge"
  }, {
    path: "/unlock",
    component: _96d6062c,
    name: "unlock"
  }, {
    path: "/enrollment/eligibility",
    component: _7fe4e253,
    name: "enrollment-eligibility"
  }, {
    path: "/enrollment/error",
    component: _7950b5ce,
    name: "enrollment-error"
  }, {
    path: "/enrollment/rejection",
    component: _4fcb7f62,
    name: "enrollment-rejection"
  }, {
    path: "/enrollment/success",
    component: _bee9ca6e,
    name: "enrollment-success"
  }, {
    path: "/errors/400",
    component: _6b846a1a,
    name: "errors-400"
  }, {
    path: "/errors/401",
    component: _6b683b18,
    name: "errors-401"
  }, {
    path: "/errors/403",
    component: _6b2fdd14,
    name: "errors-403"
  }, {
    path: "/errors/404",
    component: _6b13ae12,
    name: "errors-404"
  }, {
    path: "/errors/500",
    component: _01b7f398,
    name: "errors-500"
  }, {
    path: "/",
    component: _29dc9b9c,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
